import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderClient from '../../../Layouts/HeaderClient';
import * as reportAPI from '../../../API/reports';
import { urlfunction } from '../../../Utils/url';
import { setRootColors } from '../../../Utils/applycolors';
import { formatPhoneNumber } from '../../../Utils/formatPhoneNumber';
import ContactIconsData from '../../../Components/ContactIconsData';
import Loader from '../../../Components/Loader';
import AnalyticsScript from '../../../Components/analyticsScript';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import { source_category } from './ServicesData';

const Contact = ({ isVisible }) => {
  let navigate = useNavigate();
  const [data, setdata] = useState([]);

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  let params = useParams();

  let EmailRef = useRef();
  let NameRef = useRef();
  let DescriptionRef = useRef();

  const response = urlfunction(params);

  const handleOnLoad = async () => {
    setLoading(true);
    reportAPI.payload.actionType = 'home';
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;
    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status != 'Error') {
      setLoading(false);

      let colors = res.data.data[0];
      res.data.data.length == 0 && navigate('e');
      setdata(res.data.data[0]);
      setRootColors(
        colors.color1,
        colors.color2,
        colors.color3,
        colors.color4,
        colors.profile_photo_2
      );
      const setFavicon = (elementId, imageUrl) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.href = imageUrl;
        }
      };

      setFavicon('dynamic-favicon', res.data.data[0].profile_photo_1);
      setFavicon('apple-touch-icon', res.data.data[0].profile_photo_1);
    } else {
      setLoading(false);

      console.log(res.data.message);
    }
  };

  const submitHanlder = async (e) => {
    e.preventDefault();
    let name = NameRef.current.value;
    let email = EmailRef.current.value;
    let description = DescriptionRef.current.value;
    try {
      let res = await fetch(process.env.REACT_APP_BASE_URL + '/leads/add', {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          email: email,
          message: description,
          source_category: 'contact',
          uniqueId: response.uniqueId,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });
      let data = await res.json();

      if (res.status !== 200) {
        window.scrollTo(0, 0);
        setMessage(
          data.message.charAt(0).toUpperCase() + data.message.slice(1)
        );
      } else {
        // navigate("/thankyouPage");
        Swal.fire({
          icon: 'success',
          title: 'Thankyou For Contacting!',
          text: "We'll Get Back To You Soon.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/');
          }
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log(error);
      window.scrollTo(0, 0);
      setMessage('Problem in contacting admin.');
    }
  };

  const getNextMonth = () => {
    const nextMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1
    );
    return nextMonth.toLocaleString('en-US', { month: 'long' });
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  if (response.is_u === false) {
    return (
      <>
        <Helmet>
          <title>{`${data.name} - Contact`}</title>
        </Helmet>
        <AnalyticsScript data={data} />

        <div>
          <div className={isVisible ? 'site-main bgimg' : ''}>
            <div
              className={
                isVisible ? 'site-main-inner line-height' : 'line-height'
              }
            >
              <div className='main-section' style={{ overflowX: 'hidden' }}>
                <h1 className='main-title rootColorFore3'>Contact me</h1>
                <br />
                {loading ? <Loader>{loading}</Loader> : ''}
                <p className='fs-5 fw-light mb-0 mb-lg-3'>
                  I am available for hire and open to any ideas of cooperation.
                </p>
                <div className='d-inline d-lg-none'>
                  <hr />
                  <NextAvailable
                    getNextMonth={getNextMonth}
                    EmailRef={EmailRef}
                  />
                  <hr />
                </div>

                <div className='d-flex flex-lg-row flex-column-reverse'>
                  <div className='col-lg-8'>
                    {message && <>{message}</>}
                    <form onSubmit={submitHanlder}>
                      <div className='row'>
                        <div className='form-group'>
                          <label for='email' className='fw-light fs-5'>
                            Email address
                          </label>
                          <input
                            type='email'
                            className='form-control mb-3'
                            id='email'
                            aria-describedby='email'
                            placeholder='Enter email'
                            ref={EmailRef}
                            required
                          />
                        </div>

                        <div className='form-group'>
                          <label for='subject' className='fw-light fs-5'>
                            Name
                          </label>
                          <input
                            type='name'
                            className='form-control mb-3'
                            id='subject'
                            aria-describedby='name'
                            placeholder='Enter name'
                            ref={NameRef}
                            required
                          />
                        </div>
                        <div className='form-group'>
                          <label for='message' className='fw-light fs-5'>
                            Message
                          </label>
                          <textarea
                            className='form-control mb-3'
                            placeholder='Type your message'
                            id='message'
                            rows='7'
                            ref={DescriptionRef}
                            required
                          ></textarea>
                        </div>
                      </div>

                      <button
                        type='submit'
                        className='btn btn-primary border border-none fw-light fs-5 rootColorBg1 rootColorFore2'
                      >
                        Submit
                      </button>
                    </form>

                    <h1 className='fw-light fs-5 my-3 lh-base d-block d-lg-none'>
                      <Note />
                    </h1>
                  </div>

                  <div className='col-lg-4 ps-lg-4 pt-3'>
                    {response.is_u === false ? (
                      <div>
                        {data.email ? (
                          <h1 className='fw-light fs-5 mb-3'>
                            Email:{' '}
                            <Link
                              to={`mailto: ${data.email}`}
                              className='contact_info rootColorFore3 fw-500 text-lowercase hover-bg'
                            >
                              {data.email}
                            </Link>
                          </h1>
                        ) : (
                          ''
                        )}
                        {data.phone_no ? (
                          <h1 className='fw-light fs-5'>
                            Tel:{' '}
                            <Link
                              to={`tel:${data.phone_no}`}
                              className='contact_info rootColorFore3 fw-500 hover-bg'
                            >
                              {formatPhoneNumber(data.phone_no)}
                            </Link>
                          </h1>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className={response.is_u === false ? 'my-3' : 'mb-4'}>
                      <ContactIconsData
                        data={data}
                        is_u={response.is_u}
                        height='32'
                        width='32'
                        fontSize='fs-2'
                        padding_class='padding_class1'
                      />
                    </div>

                    {data.location ? (
                      <>
                        <hr />

                        <h1 className='fw-light fs-5 lh-base rootColorFore3'>
                          <i className='fa fa-location-dot fs-4'></i>{' '}
                          &nbsp;&nbsp;
                          <span className='contact_info fw-500'>
                            {data.location}
                          </span>
                        </h1>
                      </>
                    ) : (
                      ''
                    )}
                    <hr />
                    <div className='d-none d-lg-inline'>
                      <NextAvailable
                        getNextMonth={getNextMonth}
                        EmailRef={EmailRef}
                      />

                      <hr />
                    </div>
                    <h1 className='fw-light fs-5 mb-3 lh-base d-none d-lg-inline'>
                      <Note />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    navigate('*');
  }
};

export default Contact;

const Note = () => {
  return (
    <>
      Reach out today and I'll provide you with my comprehensive onboarding
      package designed for full-time employment or Contract Based Jobs. This
      package covers various aspects that potential employers often inquire
      about, such as my salary expectations and the seamless integration process
      into your company.
    </>
  );
};

const NextAvailable = ({ getNextMonth, EmailRef }) => {
  return (
    <>
      <h1 className='fs-5 mb-3 lh-base fw-500'>
        Next available{' '}
        <span className='fw-700'>
          {getNextMonth()} {new Date().getFullYear()}
        </span>
        .
        <span
          className='rootColorFore3 fw-600'
          onClick={() => EmailRef.current.focus()}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          Hire now.
        </span>{' '}
      </h1>
    </>
  );
};
