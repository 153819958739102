import axios from 'axios';

export let payload = {
  domain: 'http://www.findusman.me',
  uniqueId: '',
  actionType: 'home',
  itemID: '',
};

export async function list(payload) {
  let response;
  let url = process.env.REACT_APP_BASE_URL;

  try {
    response = await axios.post(url + '/reports', payload);
  } catch (error) {
    // console.log(error);
    return error.response;
  }

  return response;
}
