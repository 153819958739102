import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderClient from "../../../Layouts/HeaderClient";
import * as reportAPI from "../../../API/reports";
import { urlfunction } from "../../../Utils/url";
import { applyColors, setRootColors } from "../../../Utils/applycolors";
import Loader from "../../../Components/Loader";
import { Skeleton } from "@mui/material";
import AnalyticsScript from "../../../Components/analyticsScript";
import { modifiedContent } from "../../../Utils/modifiedContent";
import { Helmet } from "react-helmet";
import { sync } from "../../../API/skill";

const Home = ({ isVisible, setIsVisible }) => {
  const [skillsList, setSkillsList] = useState();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  const response = urlfunction(params);
  console.log(response);
  const handleOnLoad = async () => {
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;

    reportAPI.payload.actionType = "home";

    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status != "Error") {
      // res.data.data.length == 0 && navigate("e");
      let colors = res.data.data[0];

      setdata(res.data.data[0]);
      console.log("data", data);
      setRootColors(colors.color1, colors.color2, colors.color3, colors.color4);
      setLoading(true);

      const setFavicon = (elementId, imageUrl) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.href = imageUrl;
        }
      };

      // setFavicon('dynamic-favicon', res.data.data[0].profile_photo_1);
      // setFavicon('apple-touch-icon', res.data.data[0].profile_photo_1);
    } else {
      console.log("data loaded");
      // console.log(res.data.message);
    }
  };

  const siteMainStyle = {
    backgroundImage: `../`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  useEffect(() => {
    console.log("use effect end");
    handleOnLoad();
    console.log("use effect end");
  }, []);
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const typingSpeed = 20;

  useEffect(() => {
    if (data.tag_line) {
      if (currentIndex < data.tag_line.length) {
        const timer = setTimeout(() => {
          setTypedText((prevText) => prevText + data.tag_line[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, typingSpeed);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [currentIndex, data.tag_line]);

  useEffect(() => {
    const fetchData = async () => {
      const skillsData = await sync();
      setSkillsList(skillsData.data.data);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>{data.name} - Home</title>
        </Helmet>
        <AnalyticsScript data={data} />

        <div className={isVisible ? "site-main" : ""} style={siteMainStyle}>
          <div className={isVisible ? "site-main-inner" : ""}>
            <div className="home-section ps-3 ps-lg-5">
              <div className={isVisible ? "home-section-inner ps-lg-5" : ""}>
                <div className="home-heading rootColorFore3">
                  Hi, I'm {data.name}
                </div>
                <div className="home-subheading fw-light fs-1 text-white text-lowercase rootColorFore4">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: modifiedContent(typedText, skillsList),
                    }}
                  />
                  <span className="typing-cursor" />
                </div>

                <div className="home-buttons ">
                  <Link
                    to={"/" + response.uniqueId + "/offer"}
                    className="button button-outline button-white rootColorBg1 rootColorFore2 ms-2"
                  >
                    Cover Letter
                  </Link>
                  <br />
                  <Link
                    to={"/" + response.uniqueId + "/portfolio"}
                    className="button button-outline button-white rootColorBg1 rootColorFore2 ms-2"
                  >
                    Portfolio
                  </Link>
                  <br />
                  <Link
                    to={data.upwork}
                    target="_blank"
                    className="button button-outline button-white rootColorBg1 rootColorFore2 ms-2"
                  >
                    Upwork Profile
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={isVisible ? `site-main py-5 bgimg` : "py-5"}>
        <div
          className={
            isVisible
              ? "site-main-inner line-height px-lg-5 px-2 "
              : "line-height"
          }
        >
          <div className={isVisible ? "home-section-inner ps-lg-5" : ""}>
            {!loading ? <Loader>{loading}</Loader> : ""}
            <Skeleton variant="rectangular" height={50} width="40%" />
            <Skeleton
              variant="rectangular"
              height={170}
              sx={{ margin: "3em 0em" }}
            />

            <Skeleton variant="rectangular" height={50} width="30%" />
            <Skeleton
              variant="rectangular"
              height={50}
              width="30%"
              sx={{ margin: "10px 0px" }}
            />
            <Skeleton variant="rectangular" height={50} width="30%" />
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
