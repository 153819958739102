import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderClient from '../../../Layouts/HeaderClient';
import * as reportAPI from '../../../API/reports';
import { urlfunction } from '../../../Utils/url';
import { applyColors, setRootColors } from '../../../Utils/applycolors';
import PictureModalCarousel from '../../../Components/PictureModalCarousel';
import { Skeleton } from '@mui/material';
import AnalyticsScript from '../../../Components/analyticsScript';
import { modifiedContent } from '../../../Utils/modifiedContent';
import Loader from '../../../Components/Loader';
import { Helmet } from 'react-helmet';
import { handlegrouping } from '../../../Utils/handlegrouping';
import { sync } from '../../../API/skill';

const PortfolioClientDetailed = ({ isVisible, setIsVisible }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [picturesLoaded, setpicturesLoaded] = useState(false);
  const [skillsList, setSkillsList] = useState();

  const handleImageClick = (picture) => {
    setSelectedImage(picture);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  let navigate = useNavigate();
  const [data, setdata] = useState([]);

  const { id } = useParams();
  console.log('data', data);
  let params = useParams();

  const response = urlfunction(params);
  const pathUniqueID = response.path_unique_id;

  const [isLoading, setLoading] = useState(false);

  const handleOnLoad = async () => {
    reportAPI.payload.actionType = 'portfolioById';
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;
    reportAPI.payload.itemID = id;

    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status != 'Error') {
      let colors = res.data.data[0];

      setdata(res.data.data[0]);
      setRootColors(
        colors.color1,
        colors.color2,
        colors.color3,
        colors.color4,
        colors.profile_photo_2
      );

      setLoading(true);

      res.data.data.length == 0 && navigate('e');
      console.log({ data: res.data.data });

      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('s') === 'pdf') {
        setSelectedImage(res.data.data[0].portfolios_ids[0].thumbnail);
      }
      const setFavicon = (elementId, imageUrl) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.href = imageUrl;
        }
      };

      setFavicon('dynamic-favicon', res.data.data[0].profile_photo_1);
      setFavicon('apple-touch-icon', res.data.data[0].profile_photo_1);
    } else {
      console.log(res.data.message);
    }
  };

  const handleThumbnailLoad = () => {
    setpicturesLoaded(true);
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const skillsData = await sync();
      setSkillsList(skillsData.data.data);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>{`${data.name} - Portfolio`}</title>
        </Helmet>
        <AnalyticsScript data={data} />

        <div>
          <div className={isVisible ? 'site-main bgimg' : ''}>
            <div
              className={isVisible ? 'site-main-inner line-height' : 'lh-lg'}
            >
              <div className='main-section project-section'>
                <div className='d-flex justify-content-between'>
                  <div style={{ width: '70%' }}>
                    <h1 className='main-title rootColorFore3 mb-4 title-break'>
                      {data.portfolios_ids[0].title}
                    </h1>
                  </div>

                  {data.portfolios_ids[0].link && (
                    <div className='mx-md-4 ms-4'>
                      <Link
                        to={data.portfolios_ids[0].link}
                        target='_blank'
                        className='btn button-red  rootColorBg1 rootColorFore2'
                      >
                        <i className='fa fa-external-link me-1'></i>
                        View Online
                      </Link>
                    </div>
                  )}
                </div>

                <div className='project-descriptions px-0'>
                  <div
                    className='project-description project-tech_description ps-4'
                    style={{ float: 'right' }}
                  >
                    <div className='pt-md-1 technology-left-border'>
                      <div className='d-none d-md-inline screen-shown'>
                        <ThumbnailPortfolio
                          picturesLoaded={picturesLoaded}
                          handleImageClick={handleImageClick}
                          data={data}
                          handleThumbnailLoad={handleThumbnailLoad}
                        />
                      </div>

                      <div className=' d-none d-md-inline screen-shown'>
                        <AreasofExpertise data={data} />
                      </div>
                    </div>
                  </div>

                  <div className='px-0'>
                    <h2 className='project-subtitle'>
                      {data.portfolios_ids[0].tagline_1}
                    </h2>
                    <br />
                    <div
                      className='fs-5 fw-600 rootColorFore4'
                      dangerouslySetInnerHTML={{
                        __html: modifiedContent(
                          data.portfolios_ids[0].tagline_2,
                          skillsList
                        ),
                      }}
                    ></div>
                    <div className='project-descriptions px-0'>
                      <div className='project-description project-tech_description'>
                        <Link
                          to={
                            response.is_u == true
                              ? '/' +
                                pathUniqueID +
                                `/portfolio/detail/${data.portfolios_ids[0].id}/u?s=pdf`
                              : '/' +
                                pathUniqueID +
                                `/portfolio/detail/${data.portfolios_ids[0].id}?s=pdf`
                          }
                        >
                          <div className='pt-md-1 technology-left-border screen-hidden'>
                            <ThumbnailPortfolio
                              picturesLoaded={picturesLoaded}
                              handleImageClick={handleImageClick}
                              data={data}
                              handleThumbnailLoad={handleThumbnailLoad}
                            />
                          </div>
                        </Link>
                        <div className='pt-md-1 technology-left-border d-inline d-md-none'>
                          <ThumbnailPortfolio
                            picturesLoaded={picturesLoaded}
                            handleImageClick={handleImageClick}
                            data={data}
                            handleThumbnailLoad={handleThumbnailLoad}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className='mt-3 fs-5'
                      dangerouslySetInnerHTML={{
                        __html: modifiedContent(
                          data.portfolios_ids[0].features_long,
                          skillsList
                        ),
                      }}
                    ></div>

                    <div className='screen-hidden'>
                      <AreasofExpertise data={data} />
                    </div>

                    <div className='d-inline d-md-none'>
                      <AreasofExpertise data={data} />
                    </div>

                    {data.portfolios_ids[0].link && (
                      <div className='m-2 mt-4'>
                        <Link
                          to={data.portfolios_ids[0].link}
                          target='_blank'
                          className='btn button-red  rootColorBg1 rootColorFore2'
                        >
                          <i className='fa fa-external-link me-1'></i>
                          View Online
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <Link
                  to={
                    response.is_u == true
                      ? '/' +
                        pathUniqueID +
                        `/portfolio/detail/${data.portfolios_ids[0].id}/u?s=pdf`
                      : '/' +
                        pathUniqueID +
                        `/portfolio/detail/${data.portfolios_ids[0].id}?s=pdf`
                  }
                >
                  <div className='screen-hidden'>
                    <Imagegallery
                      handleImageClick={handleImageClick}
                      data={data}
                    />
                  </div>
                </Link>
                <div className='screen-shown'>
                  <Imagegallery
                    handleImageClick={handleImageClick}
                    data={data}
                  />
                </div>
              </div>

              <PictureModalCarousel
                show={selectedImage !== null}
                onHide={handleCloseModal}
                picture={selectedImage}
                selectedImage={selectedImage}
                data={data}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={isVisible ? `site-main py-5 bgimg` : 'py-5'}>
          <div
            className={
              isVisible ? 'site-main-inner lh-lg px-lg-5 px-2 ' : 'lh-lg'
            }
          >
            <div className='d-flex justify-content-between'>
              <Skeleton variant='rounded' width='15%' height={50} />
              <div>{!isLoading ? <Loader>{isLoading}</Loader> : ''}</div>
              <div></div>
            </div>

            <Skeleton
              variant='rounded'
              height={80}
              sx={{ margin: '10px 0px' }}
            />
            <div className='d-flex justify-content-between'>
              <Skeleton variant='rounded' width='49%' height={500} />
              <Skeleton variant='rounded' width='49%' height={200} />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PortfolioClientDetailed;

const ThumbnailPortfolio = ({
  picturesLoaded,
  handleImageClick,
  data,
  handleThumbnailLoad,
}) => {
  return (
    <>
      {picturesLoaded ? null : (
        <div className='d-flex justify-content-center'>
          <Skeleton
            variant='rounded'
            sx={{ borderRadius: '1rem', width: '95%', height: '20em' }}
          />
        </div>
      )}

      <div
        className={picturesLoaded ? 'text-center mt-2' : 'd-none'}
        onClick={() => handleImageClick(data.portfolios_ids[0].thumbnail)}
      >
        <img
          src={data.portfolios_ids[0].thumbnail}
          className={
            picturesLoaded
              ? 'hover-img cursor-pointer border border-2 border-dark mt-2'
              : 'd-none'
          }
          style={{
            borderRadius: '1em',
            objectFit: 'conatin',
            width: '95%',
          }}
          onLoad={handleThumbnailLoad}
        />
      </div>
    </>
  );
};

const AreasofExpertise = ({ data }) => {
  return (
    <>
      <h5 className='technologies-title fw-light fs-5'>
        Areas of expertise I've worked in
      </h5>
      <ul className='d-flex flex-wrap ps-0 list-unstyled'>
        {Object.values(handlegrouping(data.portfolios_ids[0].skills)).map(
          (group, index) => (
            <>
              <div key={index}>
                {group.map((skill) => (
                  <a
                    href={skill.url ? skill.url : null}
                    rel='nofollow'
                    target='_blank'
                    className=' rootColorBg1 rootColorFore2 badge fs-5 fw-400 me-2 p-2 mb-2 tech-list-style'
                  >
                    {skill.title}
                  </a>
                ))}
              </div>
              <hr className='text-white my-1' />
            </>
          )
        )}
      </ul>
    </>
  );
};

const Imagegallery = ({ handleImageClick, data }) => {
  return (
    <>
      <div className='project-images row'>
        <div
          className='col-12 d-flex justify-content-center '
          onClick={() => handleImageClick(data.portfolios_ids[0].thumbnail)}
        >
          <img
            className='img-fluid w-100 mb-4 border border-3 border-dark cursor-pointer'
            style={{ borderRadius: '1em' }}
            src={data.portfolios_ids[0].thumbnail}
            alt='Tripsomnia'
          />
        </div>

        {JSON.parse(data.portfolios_ids[0].pictures).map((picture) => (
          <>
            <div className='col-12 d-flex justify-content-center'>
              <img
                src={picture}
                alt='projectImages'
                className='img-fluid w-100 mb-4 border border-3 border-dark cursor-pointer'
                style={{ borderRadius: '1em' }}
                onClick={() =>
                  handleImageClick(data.portfolios_ids[0].thumbnail)
                }
              />
            </div>
          </>
        ))}
      </div>
    </>
  );
};
