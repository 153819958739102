import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderClient from '../../../Layouts/HeaderClient';
import * as reportAPI from '../../../API/reports';
import { urlfunction } from '../../../Utils/url';
import { applyColors, setRootColors } from '../../../Utils/applycolors';
import Loader from '../../../Components/Loader';
import { Skeleton } from '@mui/material';
import AnalyticsScript from '../../../Components/analyticsScript';
import { modifiedContent } from '../../../Utils/modifiedContent';
import { Helmet } from 'react-helmet';
import { sync } from '../../../API/skill';

const Offer = ({ isVisible, setIsVisible }) => {
  let navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [skillsList, setSkillsList] = useState();

  const [loading, setLoading] = useState(false);

  let params = useParams();

  const response = urlfunction(params);

  const handleOnLoad = async () => {
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;

    reportAPI.payload.actionType = 'home';

    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status != 'Error') {
      res.data.data.length == 0 && navigate('e');

      let colors = res.data.data[0];

      setdata(res.data.data[0]);

      setRootColors(
        colors.color1,
        colors.color2,
        colors.color3,
        colors.color4,
        colors.profile_photo_2
      );

      setLoading(true);

      const setFavicon = (elementId, imageUrl) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.href = imageUrl;
        }
      };

      setFavicon('dynamic-favicon', res.data.data[0].profile_photo_1);
      setFavicon('apple-touch-icon', res.data.data[0].profile_photo_1);
    } else {
      console.log(res.data.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const skillsData = await sync();
      setSkillsList(skillsData.data.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleOnLoad();
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>{data.name} - Cover Letter</title>
        </Helmet>
        <AnalyticsScript data={data} />

        <div>
          <div className={isVisible ? 'site-main fs-5 bgimg' : ''}>
            <div
              className={
                isVisible ? 'site-main-inner line-height' : ' line-height'
              }
            >
              <div className='main-section offer-section'>
                <h1 className='main-title rootColorFore3'>Cover Letter</h1>
                <br />
                <div
                  className='fw-light'
                  dangerouslySetInnerHTML={{
                    __html: modifiedContent(data.description, skillsList),
                  }}
                ></div>{' '}
                <div
                  className='fw-light'
                  dangerouslySetInnerHTML={{
                    __html: modifiedContent(
                      data.category1_description,
                      skillsList
                    ),
                  }}
                ></div>
                <div
                  className='fw-light'
                  dangerouslySetInnerHTML={{
                    __html: modifiedContent(
                      data.category2_description,
                      skillsList
                    ),
                  }}
                ></div>
                <div
                  className='fw-light'
                  dangerouslySetInnerHTML={{
                    __html: modifiedContent(
                      data.category3_description,
                      skillsList
                    ),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className={isVisible ? `site-main py-5 bgimg` : 'py-5'}>
        <div
          className={
            isVisible ? 'site-main-inner lh-lg px-lg-5 px-2 ' : 'lh-lg'
          }
        >
          <div className='d-flex justify-content-between'>
            <Skeleton
              variant='rounded'
              width='15%'
              height={50}
              sx={{ marginBottom: '10px' }}
            />
            <div>{!loading ? <Loader>{loading}</Loader> : ''}</div>
            <div></div>
          </div>

          <Skeleton
            variant='rounded'
            width='70%'
            height={40}
            sx={{ marginBottom: '10px' }}
          />
          <Skeleton
            variant='rounded'
            height={40}
            sx={{ marginBottom: '10px' }}
          />
          <Skeleton variant='rounded' height={500} />
        </div>
      </div>
    );
  }
};

export default Offer;
