import React, { useRef, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { urlfunction } from "../Utils/url";
import HeaderIconsData from "../Components/HeaderIconsData";
import { Skeleton } from "@mui/material";
import * as reportAPI from "../API/reports";

const HeaderClient = ({ isVisible, setIsVisible }) => {
  const response = urlfunction();
  let navigate = useNavigate();
  const location = useLocation();
  const is_u = response.is_u;

  const uniqueID = response.path_unique_id;
  const [data, setdata] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isProfilePhotoLoaded, setIsProfilePhotoLoaded] = useState(false);

  let menuRef = useRef();

  const handleOnLoad = async () => {
    reportAPI.payload.actionType = "home";
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;
    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status != "Error") {
      res.data.data.length == 0 && navigate("e");
      setdata(res.data.data[0]);
    } else {
      console.log(res.data.message);
    }
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 86) {
      setIsVisible((prevVisible) => !prevVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown1 = (e) => {
    if (e.altKey && e.shiftKey && e.keyCode === 85) {
      if (
        location.pathname === "/" + uniqueID ||
        location.pathname === "/" ||
        location.pathname === ""
      ) {
        if (localStorage.getItem("is_u")) {
          localStorage.removeItem("is_u");
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown1);

    return () => {
      window.removeEventListener("keydown", handleKeyDown1);
    };
  }, []);

  useEffect(() => {
    let handler = (e) => {
      if (isVisible) {
        if (!menuRef.current.contains(e.target)) {
          setExpanded(false);
        }
        document.addEventListener("mousedown", handler);
        return () => {
          document.removeEventListener("mousedown", handler);
        };
      }
    };
  });

  const handleProfilePhotoLoad = () => {
    setIsProfilePhotoLoaded(true);
  };

  return (
    <>
      {isVisible ? (
        <>
          <aside className="site-sidebar rootColorBg1">
            <div className="site-sidebar-inner d-flex flex-column">
              <div className="person u-url">
                <Link to={"/" + uniqueID} rel="me">
                  <div className="person-avatar u-photo d-flex align-items-center flex-column hover-img">
                    {!isProfilePhotoLoaded ? (
                      <Skeleton variant="circular" width={152} height={152} />
                    ) : null}
                    <img
                      src={data.profile_photo_1}
                      alt=""
                      className={`avatar ${
                        isProfilePhotoLoaded ? "text-center " : "d-none"
                      }`}
                      onLoad={handleProfilePhotoLoad}
                    />
                  </div>
                </Link>
                <div className="person-content">
                  <Link to={"/" + uniqueID}>
                    <h1 className="person-title p-name pt-2 rootColorFore2 hover-img">
                      {data.name}
                    </h1>
                  </Link>
                  <Link to={"/" + uniqueID}>
                    <h3 className="person-subtitle p-job-title p-note pt-1 pb-2 pb-lg-0 rootColorFore2 hover-img">
                      {data.designation}
                    </h3>
                  </Link>
                </div>
              </div>

              <hr className="d-none d-lg-block" />

              <div className="screen-shown d-lg-block d-none ">
                <MainNavigation
                  data={data}
                  location={location}
                  response={response}
                  uniqueID={uniqueID}
                />
              </div>

              <div className="screen-hidden">
                <MainNavigation
                  data={data}
                  location={location}
                  response={response}
                  uniqueID={uniqueID}
                />
              </div>
            </div>
          </aside>

          <Navbar
            className="d-block d-lg-none screen-shown navbar-dark rootColorBg1"
            style={{
              borderTop: "1px solid rgba(255,255,255,.2)",
              borderBottom: "1px solid rgba(255,255,255,.2)",
            }}
            expand="lg"
            expanded={expanded}
          >
            <Container fluid>
              <div className="d-flex ps-2">
                {is_u === false ? (
                  <HeaderIconsData
                    data={data}
                    is_u={is_u}
                    width="16"
                    height="16"
                  />
                ) : (
                  ""
                )}
              </div>

              <Navbar.Toggle
                aria-controls="navbar-nav"
                className="navbar-toggle "
                ref={menuRef}
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />

              <Navbar.Collapse
                id="navbar-nav"
                className="ps-2 "
                onClick={() => setExpanded(expanded ? false : "expanded")}
              >
                <Nav className="me-auto nav-no-hover">
                  <Nav.Link
                    as={NavLink}
                    to={
                      response.is_u == true
                        ? "/" + uniqueID + "/u"
                        : "/" + uniqueID
                    }
                    className="rootColorFore2 mt-2 "
                  >
                    <i class="fa-regular fa-file-lines me-3"></i>
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to={
                      response.is_u == true
                        ? "/" + uniqueID + "/offer/u"
                        : "/" + uniqueID + "/offer"
                    }
                    className="rootColorFore2 mt-2 "
                  >
                    <i class="fa-regular fa-file-lines me-3"></i>
                    Cover Letter
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to={
                      response.is_u == true
                        ? "/" + uniqueID + "/portfolio/u"
                        : "/" + uniqueID + "/portfolio"
                    }
                    className="rootColorFore2"
                  >
                    <i class="fa-solid fa-briefcase me-3"></i>
                    Portfolio
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to={"/" + uniqueID + "/contact"}
                    className="rootColorFore2"
                  >
                    <i class="fa-solid fa-phone-volume me-3"></i>
                    Contact Me
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to={
                      response.is_u == true
                        ? "/" + uniqueID + "/cv/u"
                        : "/" + uniqueID + "/cv"
                    }
                    className="rootColorFore2"
                  >
                    <i class="fa-regular fa-address-book me-3"></i>
                    CV
                  </Nav.Link>
                  {data.linkedIn && is_u === false ? (
                    <>
                      {" "}
                      <Nav.Link
                        as={NavLink}
                        to={data.linkedIn}
                        target="_blank"
                        className="rootColorFore2"
                      >
                        <i className="fa fa-linkedin me-3"></i>
                        Linkedin
                      </Nav.Link>
                    </>
                  ) : (
                    ""
                  )}
                  {data.github ? (
                    <>
                      {" "}
                      <Nav.Link
                        as={NavLink}
                        to={data.github}
                        target="_blank"
                        className="rootColorFore2"
                      >
                        <i className="fa fa-github me-3"></i>
                        Github
                      </Nav.Link>
                    </>
                  ) : (
                    ""
                  )}
                  {data.upwork ? (
                    <>
                      {" "}
                      <Nav.Link
                        as={NavLink}
                        to={data.upwork}
                        target="_blank"
                        className="rootColorFore2 button-icon upwork-svg"
                      >
                        <span className="me-3">
                          <svg
                            role="img"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            id="IconChangeColor"
                            height={14}
                            width={14}
                          >
                            <path
                              d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076.008-.042c.207-1.143.849-3.06 2.839-3.06 1.492 0 2.703 1.212 2.703 2.703-.001 1.489-1.212 2.702-2.704 2.702zm0-8.14c-2.539 0-4.51 1.649-5.31 4.366-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112c-.002 1.406-1.141 2.546-2.547 2.548-1.405-.002-2.543-1.143-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303 2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109 3 0 5.439-2.452 5.439-5.45 0-3-2.439-5.439-5.439-5.439z"
                              id="mainIconPathAttribute"
                            ></path>
                          </svg>
                        </span>
                        Upwork
                      </Nav.Link>
                    </>
                  ) : (
                    ""
                  )}
                  {data.location && is_u === false ? (
                    <div className="me-2">
                      <hr className="text-light" />
                      <div className="block-title mb-1 rootColorFore2 fs-6 text-end">
                        <i className="fa fa-location-dot fs-6"></i> &nbsp;
                        {data.location}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </>
      ) : null}
    </>
  );
};

export default HeaderClient;

const MainNavigation = ({ response, data, location, uniqueID }) => {
  return (
    <>
      <nav className="block main-navigation mt-0">
        {/* <button onClick={window.print}>Print</button> */}
        <div
          className={!response.is_u ? "sitebar-list" : "pe-3"}
          style={{ overflowY: "auto" }}
        >
          <ul className="g-1">
            <li
              className={`header-list-hover ${
                location.pathname.includes("/home") ? "activehover" : ""
              }`}
            >
              <SidebarMenu
                icon="fa-regular fa-file-lines"
                menu="Home"
                to={
                  response.is_u == true ? "/" + uniqueID + "/u" : "/" + uniqueID
                }
              />
            </li>
            <li
              className={`header-list-hover ${
                location.pathname.includes("/offer") ? "activehover" : ""
              }`}
            >
              <SidebarMenu
                icon="fa-regular fa-file-lines"
                menu="Cover Letter"
                to={
                  response.is_u == true
                    ? "/" + uniqueID + "/offer/u"
                    : "/" + uniqueID + "/offer"
                }
              />
            </li>
            <li
              className={`header-list-hover ${
                location.pathname.includes("/portfolio") ? "activehover" : ""
              }`}
            >
              <SidebarMenu
                icon="fa-solid fa-briefcase"
                menu="Portfolio"
                to={
                  response.is_u == true
                    ? "/" + uniqueID + "/portfolio/u"
                    : "/" + uniqueID + "/portfolio"
                }
              />
            </li>
            {response.is_u === false ? (
              <li
                className={`header-list-hover ${
                  location.pathname.includes("/contact") ? "activehover" : ""
                }`}
              >
                <SidebarMenu
                  icon="fa-solid fa-phone-volume"
                  menu="Contact me"
                  to={"/" + uniqueID + "/contact"}
                />
              </li>
            ) : (
              ""
            )}
            <li className="header-list-hover">
              <SidebarMenu
                icon="fa-regular fa-address-book"
                menu="CV"
                to={
                  response.is_u == true
                    ? "/" + uniqueID + "/cv/u"
                    : "/" + uniqueID + "/cv"
                }
              />
            </li>
            {data.linkedIn && response.is_u === false ? (
              <>
                <li className="header-list-hover">
                  <SidebarMenu
                    icon="fa fa-linkedin"
                    menu="Linkedin"
                    to={data.linkedIn}
                    target="_blank"
                  />
                </li>
              </>
            ) : (
              ""
            )}
            {data.github ? (
              <>
                <li className="header-list-hover">
                  <SidebarMenu
                    icon="fa fa-github"
                    menu="Github"
                    to={data.github}
                    target="_blank"
                  />
                </li>
              </>
            ) : (
              ""
            )}
            {data.upwork ? (
              <>
                {" "}
                <li className="header-list-hover">
                  <Link
                    to={data.upwork}
                    className="header-list-items rootColorFore2 button-icon upwork-svg"
                    target="_blank"
                  >
                    <div className="row">
                      <div className="col-3 ps-3 text-end">
                        <svg
                          role="img"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          id="IconChangeColor"
                          height={16}
                          width={16}
                        >
                          <path
                            d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076.008-.042c.207-1.143.849-3.06 2.839-3.06 1.492 0 2.703 1.212 2.703 2.703-.001 1.489-1.212 2.702-2.704 2.702zm0-8.14c-2.539 0-4.51 1.649-5.31 4.366-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112c-.002 1.406-1.141 2.546-2.547 2.548-1.405-.002-2.543-1.143-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303 2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109 3 0 5.439-2.452 5.439-5.45 0-3-2.439-5.439-5.439-5.439z"
                            id="mainIconPathAttribute"
                          ></path>
                        </svg>
                      </div>
                      <div className="col-9 ps-1">
                        <span data-key="t-dashboards">Upwork</span>
                      </div>
                    </div>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </nav>

      {data.email &&
      response.is_u === false &&
      (data.linkedIn ||
        data.github ||
        data.facebook ||
        data.upwork ||
        data.phone_no ||
        data.twitter ||
        data.instagram ||
        data.location) ? (
        <div
          className="block block-social mb-3"
          style={{ position: "absolute", bottom: "0", width: "78%" }}
        >
          {data.location ? (
            <div>
              <div className="block-title mb-1 rootColorFore2 fs-5">
                <i className="fa fa-location-dot fs-5"></i> &nbsp;
                {data.location}
              </div>

              <hr />
            </div>
          ) : (
            ""
          )}

          <Link to={"/contact"}>
            <div
              className="block-title mb-1 rootColorFore2"
              style={{ fontSize: "11px" }}
            >
              Get in touch
            </div>
          </Link>
          <HeaderIconsData
            data={data}
            is_u={response.is_u}
            width="19"
            height="19"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const SidebarMenu = ({ to, icon, menu, target }) => {
  return (
    <>
      <Link
        to={to}
        className="header-list-items rootColorFore2"
        target={target}
      >
        <div className="row">
          <div className="col-3 ps-3 text-end">
            <i className={`${icon}`}></i>
          </div>
          <div className="col-9 ps-1">
            <span data-key="t-dashboards">{menu}</span>
          </div>
        </div>
      </Link>
    </>
  );
};
