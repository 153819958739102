export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "short" });
  
    return `${month} ${year}`;
  };


  export const formatDateforYear = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
  
    return `${year}`;
  };
