import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HeaderClient from '../../../Layouts/HeaderClient';
import * as reportAPI from '../../../API/reports';
import { urlfunction } from '../../../Utils/url';
import { formatDateforYear } from '../../../Utils/convertedDate';
import { applyColors, setRootColors } from '../../../Utils/applycolors';
import PictureModalCarousel from '../../../Components/PictureModalCarousel';
import Loader from '../../../Components/Loader';
import { Skeleton } from '@mui/material';
import AnalyticsScript from '../../../Components/analyticsScript';
import { modifiedContent } from '../../../Utils/modifiedContent';
import { Helmet } from 'react-helmet';
import { handlegrouping } from '../../../Utils/handlegrouping';
import { sync } from '../../../API/skill';

const PortfolioClient = ({ isVisible, setIsVisible }) => {
  let navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [picturesLoaded, setpicturesLoaded] = useState(false);
  const [skillsList, setSkillsList] = useState();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  let params = useParams();
  const response = urlfunction(params);
  const uniqueID = response.uniqueId;
  const pathUniqueID = response.path_unique_id;

  const handleOnLoad = async () => {
    reportAPI.payload.actionType = 'portfolio';
    reportAPI.payload.uniqueId = response.uniqueId;
    reportAPI.payload.domain = response.domain;
    let res = await reportAPI.list(reportAPI.payload);

    if (res.data.status !== 'Error') {
      res.data.data.length === 0 && navigate('e');
      let colors = res.data.data[0];

      setdata(res.data.data[0]);

      console.log(res.data.data[0]);
      setRootColors(
        colors.color1,
        colors.color2,
        colors.color3,
        colors.color4,
        colors.profile_photo_2
      );
      setLoading(true);
      const setFavicon = (elementId, imageUrl) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.href = imageUrl;
        }
      };

      setFavicon('dynamic-favicon', res.data.data[0].profile_photo_1);
      setFavicon('apple-touch-icon', res.data.data[0].profile_photo_1);
    } else {
      console.log(res.data.message);
    }
  };

  useEffect(() => {
    handleOnLoad();
  }, []);

  const handleImageClick = (picture) => {
    setSelectedImage(picture);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleThumbnailLoad = () => {
    setpicturesLoaded(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const skillsData = await sync();
      setSkillsList(skillsData.data.data);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>{data.name} - Portfolio</title>
        </Helmet>
        <AnalyticsScript data={data} />

        <div>
          <div className={isVisible ? `site-main bgimg` : ''}>
            <div
              className={
                isVisible ? 'site-main-inner line-height' : 'line-height'
              }
            >
              <div className='main-section' style={{ paddingRight: '10px' }}>
                <div className=''>
                  <h1 className='main-title rootColorFore3'>Portfolios</h1>

                  <br className='d-none d-sm-block' />

                  <div className='timeline portfolio-timeline mb-5'>
                    <ul>
                      <li className='timeline_element timeline_element--now project'>
                        <div className='timeline_element-date '>
                          <time className='timeline_element-date-text red-date'>
                            Now
                          </time>
                        </div>

                        <div className='timeline_element-contents'>
                          <div className='project-text'>
                            <div className='project-description fw-light mb-4'>
                              <p
                                className='porfolio-list mb-0'
                                dangerouslySetInnerHTML={{
                                  __html: modifiedContent(
                                    data.home_page_tagline,
                                    skillsList
                                  ),
                                }}
                              ></p>
                              {response.is_u === false ? (
                                <p className='porfolio-list'>
                                  <Link
                                    to={'/contact'}
                                    className='rootColorFore3'
                                  >
                                    Contact me{' '}
                                  </Link>
                                  . I will answer you in ~4 hours.
                                </p>
                              ) : (
                                <p className='porfolio-list'>
                                  <Link
                                    to={data.upwork}
                                    className='rootColorFore3'
                                  >
                                    Contact me{' '}
                                  </Link>
                                  . I will answer you in ~4 hours.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>

                      {data.portfolios_ids
                        ?.sort(
                          (a, b) =>
                            new Date(b.ending_Date) - new Date(a.ending_Date)
                        )
                        .map((portfolio) => {
                          const skills = handlegrouping(portfolio.skills);
                          return (
                            <li className='timeline_element project'>
                              <div className='timeline_element-date'>
                                <time className='timeline_element-date-text red-date'>
                                  {formatDateforYear(portfolio.starting_date)} -{' '}
                                  {formatDateforYear(portfolio.ending_Date)}
                                </time>
                              </div>

                              <div className='timeline_element-contents'>
                                <div className='d-flex justify-content-between'>
                                  <div
                                    className='project-title mb-4'
                                    style={{ width: '70%' }}
                                  >
                                    <Link
                                      to={
                                        response.is_u == true
                                          ? '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}/u`
                                          : '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}`
                                      }
                                      // onClick={() => window.scrollTo(0, 0)}
                                      className='text-dark title-break'
                                    >
                                      {portfolio.title}
                                    </Link>
                                  </div>

                                  <div className='mx-3'>
                                    <Link
                                      to={
                                        response.is_u == true
                                          ? '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}/u`
                                          : '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}`
                                      }
                                      // onClick={() => window.scrollTo(0, 0)}
                                      className='project-readmore btn button-red portfolio_detail_button  rootColorBg1 rootColorFore2'
                                    >
                                      More details
                                    </Link>
                                  </div>
                                </div>

                                <div className=''>
                                  <div
                                    className='project-image screen-shown d-none d-lg-inline ps-1'
                                    style={{ float: 'right' }}
                                  >
                                    <PortfolioImageSection
                                      picturesLoaded={picturesLoaded}
                                      portfolio={portfolio}
                                      handleImageClick={handleImageClick}
                                      handleThumbnailLoad={handleThumbnailLoad}
                                    />
                                  </div>
                                  <div
                                    className='project-image screen-hidden'
                                    style={{ float: 'right' }}
                                  >
                                    <Link
                                      to={
                                        response.is_u == true
                                          ? '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}/u?s=pdf`
                                          : '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}?s=pdf`
                                      }
                                    >
                                      <div className='d-flex justify-content-center'>
                                        <img
                                          className='cursor-pointer border border-1 border-dark 
                                      d-block'
                                          style={{ borderRadius: '1em' }}
                                          src={portfolio.thumbnail}
                                          alt='Tripsomnia'
                                        />
                                      </div>

                                      <div
                                        className='row d-flex justify-content-center mt-3 cursor-pointer'
                                        onClick={() =>
                                          handleImageClick(portfolio.thumbnail)
                                        }
                                      >
                                        {JSON.parse(portfolio.pictures)
                                          .slice(0, 3)
                                          .map((picture) => (
                                            <>
                                              <div
                                                className='col-2 mt-2'
                                                style={{
                                                  height: '3.3em',
                                                  width: '4.5em',
                                                }}
                                                key={picture}
                                              >
                                                <img
                                                  src={picture}
                                                  alt='projectImages'
                                                  className='col-2 border border-1 border-dark'
                                                  style={{
                                                    height: '2.5em',
                                                    width: '4.25em',

                                                    borderRadius: '0.5em',
                                                  }}
                                                />
                                              </div>
                                            </>
                                          ))}

                                        <span className='more-pictures text-decoration-underline'>
                                          ... more{' '}
                                          {JSON.parse(portfolio.pictures)
                                            .length - 3}{' '}
                                          pictures
                                        </span>
                                      </div>
                                    </Link>
                                  </div>

                                  <div>
                                    <div className='project-subtitle'>
                                      {portfolio.tagline_1}
                                    </div>

                                    <div className='project-description'>
                                      <p className='rootColorFore4'>
                                        <strong>{portfolio.tagline_2}</strong>
                                      </p>

                                      <div className='project-image screen-shown d-inline d-lg-none ps-1'>
                                        <PortfolioImageSection
                                          picturesLoaded={picturesLoaded}
                                          portfolio={portfolio}
                                          handleImageClick={handleImageClick}
                                          handleThumbnailLoad={
                                            handleThumbnailLoad
                                          }
                                        />
                                      </div>

                                      <br className='d-none d-lg-block' />

                                      <div
                                        className='porfolio-list'
                                        dangerouslySetInnerHTML={{
                                          __html: modifiedContent(
                                            portfolio.features_short,
                                            skillsList
                                          ),
                                        }}
                                      ></div>
                                    </div>
                                    <br />

                                    <Link
                                      to={
                                        response.is_u == true
                                          ? '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}/u`
                                          : '/' +
                                            pathUniqueID +
                                            `/portfolio/detail/${portfolio.id}`
                                      }
                                      // onClick={() => window.scrollTo(0, 0)}
                                      className='project-readmore ms-2 btn button-red portfolio_detail_button  rootColorBg1 rootColorFore2'
                                    >
                                      More details
                                    </Link>
                                    <br />

                                    <br />

                                    <div className='project-technologies mb-4 mt-3'>
                                      <div className='technologies-title pb-0'>
                                        Areas of expertise I've worked in
                                      </div>
                                      <ul className='d-flex flex-wrap mt-3 ps-0  list-unstyled ms-2'>
                                        {Object.values(skills).map(
                                          (group, index) => (
                                            <>
                                              <div key={index}>
                                                {group.map((skill) => (
                                                  <a
                                                    href={
                                                      skill.url
                                                        ? skill.url
                                                        : null
                                                    }
                                                    rel='nofollow'
                                                    target='_blank'
                                                    className=' rootColorBg1 rootColorFore2 badge fs-5 fw-400 me-2 p-2 mb-2 tech-list-style'
                                                  >
                                                    {skill.title}
                                                  </a>
                                                ))}
                                              </div>
                                              <hr className='text-white my-1' />
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PictureModalCarousel
          show={selectedImage !== null}
          onHide={handleCloseModal}
          picture={selectedImage}
          selectedImage={selectedImage}
          data={data}
        />
      </>
    );
  } else {
    return (
      <div className={isVisible ? `site-main py-5 bgimg` : 'py-5 '}>
        <div
          className={
            isVisible ? 'site-main-inner lh-lg px-lg-5 px-2 ' : 'lh-lg'
          }
        >
          <div className='d-flex justify-content-between'>
            <Skeleton
              variant='rounded'
              width='15%'
              height={50}
              sx={{ marginBottom: '1em' }}
            />
            <div>{!loading ? <Loader>{loading}</Loader> : ''}</div>
            <div></div>
          </div>

          <Skeleton variant='rounded' width='70%' height={40} />
          <div className='d-flex justify-content-between my-3'>
            <Skeleton variant='rounded' width='13%' height={50} />
            <Skeleton variant='rounded' width='85%' height={50} />
          </div>
          <div className='d-flex justify-content-between'>
            <Skeleton variant='rounded' width='13%' height={500} />
            <Skeleton variant='rounded' width='85%' height={500} />
          </div>
        </div>
      </div>
    );
  }
};

export default PortfolioClient;

const PortfolioImageSection = ({
  picturesLoaded,
  portfolio,
  handleImageClick,
  handleThumbnailLoad,
}) => {
  return (
    <div>
      {picturesLoaded ? null : (
        <Skeleton
          variant='rounded'
          sx={{ borderRadius: '1rem' }}
          height={270}
        />
      )}

      <div
        className={picturesLoaded ? 'd-flex justify-content-center' : 'd-none'}
        onClick={() => handleImageClick(portfolio.thumbnail)}
      >
        <img
          className={`cursor-pointer border border-1 border-dark hover-img ${
            picturesLoaded ? 'd-block' : 'd-none'
          }`}
          style={{
            borderRadius: '1em',
            width: '90%',
          }}
          src={portfolio.thumbnail}
          alt='Tripsomnia'
          onLoad={handleThumbnailLoad}
        />
      </div>

      <div
        className='row d-flex justify-content-center mt-3 text-center mx-3 cursor-pointer'
        onClick={() => handleImageClick(portfolio.thumbnail)}
      >
        {JSON.parse(portfolio.pictures)
          .slice(0, 8)
          .map((picture) => (
            <div
              className={picturesLoaded ? 'col-2 mt-2' : 'col-2 me-1 mb-1'}
              style={{
                height: picturesLoaded ? '3.3em' : '4em',
                width: picturesLoaded ? '4.5em' : '5em',
              }}
              key={picture}
            >
              {picturesLoaded ? (
                <img
                  src={picture}
                  alt='projectImages'
                  className='border border-1 border-dark hover-img'
                  style={{
                    height: '2.5em',
                    width: '4.25em',
                    borderRadius: '0.5em',
                  }}
                />
              ) : (
                <Skeleton
                  variant='rounded'
                  sx={{
                    height: '3em',
                    width: '3.5em',
                    borderRadius: '0.5em',
                  }}
                />
              )}
            </div>
          ))}

        <span className='more-pictures mb-3 mb-xl-0'>
          ... more {JSON.parse(portfolio.pictures).length - 8} pictures
        </span>
      </div>
    </div>
  );
};
