import React from "react";
import { Link } from "react-router-dom";

const HeaderIconsData = ({ data, is_u, width, height }) => {
  return (
    <>
      <div className="block-content">
        <div className="social-icons">

        {data.upwork ? (
            <Link
              rel="me"
              to={data.upwork}
              className={
                is_u == false
                  ? "pe-2 button button-icon upwork-svg"
                  : "ps-0 pe-3 upwork-svg"
              }
              target="_blank"
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="IconChangeColor"
                height={height}
                width={width}
              >
                <path
                  d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076.008-.042c.207-1.143.849-3.06 2.839-3.06 1.492 0 2.703 1.212 2.703 2.703-.001 1.489-1.212 2.702-2.704 2.702zm0-8.14c-2.539 0-4.51 1.649-5.31 4.366-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112c-.002 1.406-1.141 2.546-2.547 2.548-1.405-.002-2.543-1.143-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303 2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109 3 0 5.439-2.452 5.439-5.45 0-3-2.439-5.439-5.439-5.439z"
                  id="mainIconPathAttribute"
                ></path>
              </svg>
            </Link>
          ) : (
            ""
          )}

{data.github ? (
            <Link
              rel="me"
              to={data.github}
              className="pe-2 button button-icon rootColorFore2"
              target="_blank"
            >
              <i className="fa fa-github"></i>
            </Link>
          ) : (
            ""
          )}

{data.linkedIn ? (
            <Link
              rel="me"
              to={data.linkedIn}
              className={
                is_u == false
                  ? "pe-2 button button-icon rootColorFore2"
                  : "ps-0 pe-3 rootColorFore2"
              }
              target="_blank"
            >
              <i className="fa fa-linkedin"></i>
            </Link>
          ) : (
            ""
          )}


          {data.email && is_u === false ? (
            <Link
              rel="me"
              to={"mailto:" + data.email}
              className="button button-icon u-email pe-2 rootColorFore2"
              title={"mailto: " + data.email}
            >
              <i className="fa fa-envelope"></i>
            </Link>
          ) : (
            ""
          )}

{data.phone_no && is_u == false ? (
            <>
              <Link
                rel="me"
                to={`tel: ${data.phone_no}`}
                className="pe-2 button button-icon rootColorFore2"
                target="_blank"
              >
                <i className="fa fa-phone"></i>
              </Link>
            </>
          ) : (
            ""
          )}


{data.phone_no && is_u == false ? (
            <>
              <Link
                rel="me"
                to={`https://api.whatsapp.com/send?phone=${data.phone_no}`}
                className="pe-2 button button-icon rootColorFore2"
                target="_blank"
              >
                <i className="fa fa-whatsapp"></i>
              </Link>
            </>
          ) : (
            ""
          )}

{data.twitter && is_u == false ? (
            <>
              <Link
                rel="me"
                to={data.twitter}
                className="pe-2 button button-icon rootColorFore2"
                target="_blank"
              >
                <i className="fa fa-twitter"></i>
              </Link>
            </>
          ) : (
            ""
          )}

          
          {data.instagram && is_u == false ? (
            <>
              <Link
                rel="me"
                to={data.instagram}
                className="pe-2 button button-icon rootColorFore2"
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </Link>
            </>
          ) : (
            ""
          )}

          
          {data.facebook && is_u == false ? (
            <>
              <Link
                rel="me"
                to={data.facebook}
                className="pe-2 button button-icon rootColorFore2"
                target="_blank"
              >
                <i className="fa fa-facebook"></i>
              </Link>
            </>
          ) : (
            ""
          )}

        

          



          
        </div>
      </div>
    </>
  );
};

export default HeaderIconsData;
