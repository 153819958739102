import Swal from 'sweetalert2';

function handleKeyPress(event) {
  if (event.ctrlKey && event.altKey && event.key === 'e') {
    // alert('print')
    // const is_admin = localStorage.getItem('is_admin');
    // localStorage.setItem('is_admin', is_admin === 'true' ? 'false' : 'true');
    // Swal.fire({
    //   text: is_admin === 'true' ? 'Admin mode is off.' : 'Admin mode is on.',
    //   position: 'top-end',
    //   customClass: 'swal-class',
    //   showConfirmButton: false,
    //   timer: 2000,
    // });
  }
}

// Add an event listener for keydown events on the document
document.addEventListener('keydown', handleKeyPress);

export function urlfunction(parameters) {
  const KEY_IS_U = 'is_u';
  const KEY_UNIQUE_ID = 'unique_id';
  const KEY_IS_ADMIN = 'is_admin';

  const response = {
    domain: '',
    uniqueId: '',
    is_u: false,
    path_unique_id: '',
  };
  // localStorage.setItem(KEY_IS_ADMIN, 'true')

  const url = new URL(window.location.href);
  const urlPathArray = url.pathname.split('/');
  const uniqueID = url.pathname.split('/')[1];
  let unique_id = null;
  const domain = url.origin.replace(/^https?:\/\//, '').replace(/^www\./, '');
  response.domain = domain;

  if (uniqueID) {
    if (uniqueID.length == 4 || uniqueID.length == 1) unique_id = uniqueID;
    else unique_id = localStorage.getItem(KEY_UNIQUE_ID);
  } else unique_id = localStorage.getItem(KEY_UNIQUE_ID);

  if (!unique_id) unique_id = 'a';
  localStorage.setItem(KEY_UNIQUE_ID, unique_id);
  const is_u = localStorage.getItem(KEY_IS_U);

  response.uniqueId = unique_id;
  response.path_unique_id = unique_id;

  if (urlPathArray.some((segment) => segment === 'admin')) {
    response.uniqueId = unique_id;
  }

  response.is_u = false;

  if (is_u == null) {
    if (
      !urlPathArray.includes('admin') &&
      parameters?.u != undefined &&
      parameters?.u?.length === 1 &&
      parameters?.u.toLowerCase() === 'u'
    ) {
      response.is_u = true;
      localStorage.setItem(KEY_IS_U, true);
    }
  } else {
    response.is_u = true;
  }

  if (!localStorage.getItem(KEY_IS_ADMIN)) {
    // if (!unique_id || uniqueID.length < 5) {
    //   unique_id = uniqueID.length < 5 ? uniqueID : 'a';
    //   localStorage.setItem(KEY_UNIQUE_ID, unique_id);
    // }
  } else {
    // if (!unique_id) {
    //   response.uniqueId = uniqueID.length == 1 ? uniqueID : 'a';
    //   response.path_unique_id = uniqueID.length == 1 ? uniqueID : 'a';
    //   localStorage.setItem(KEY_UNIQUE_ID, response.uniqueId);
    // } else {
    //   response.uniqueId = localStorage.getItem(KEY_UNIQUE_ID);
    //   response.path_unique_id = uniqueID.length == 1 ? uniqueID : 'a';
    // }
    // response.is_u = false;
    // if (is_u == null) {
    //   if (
    //     urlPathArray[2] != 'admin' &&
    //     parameters?.u != undefined &&
    //     parameters?.u?.length == 1 &&
    //     parameters?.u.toLowerCase() == 'u'
    //   ) {
    //     response.is_u = true;
    //     localStorage.setItem(KEY_IS_U, true);
    //   }
    // } else {
    //   response.is_u = true;
    // }
  }

  return response;
}
