import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


reportWebVitals();
