import React, { useEffect } from 'react';

const AnalyticsScript = ({ data }) => {
  useEffect(() => {
    if (data.googleAnalyticsScript) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + data.googleAnalyticsScript;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        // Define the dataLayer variable
        window.dataLayer = window.dataLayer || [];
        
        function gtag() {
          window.dataLayer.push(arguments);
        }
        
        gtag('js', new Date());
        gtag('config', data.googleAnalyticsScript);
      };
    }
  }, [data.googleAnalyticsScript]);

  return null;
};

export default AnalyticsScript;
