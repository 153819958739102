import axios from 'axios';

export let payload = {
  id: '',
  title: 'Wordpress',
  url: '',
  catagory: '',
  description: '',

  order: '',
  skillsIds:
    '{"skills_ids": ["9a78d633-53a0-4a70-9770-2adf8d5305e1","9a78d633-53a0-4a70-9770-2adf8d5305e1"]}',
  actionType: 'list',
  companytype: '',
};

export async function sync() {
  let response;
  let url = process.env.REACT_APP_BASE_URL;

  try {
    response = await axios.post(url + '/skill/sync', payload);
  } catch (error) {
    console.error(error.response.data);
    return error.response;
  }

  return response;
}
