export const services = [{
    id: 1,
    value: 'frontend',
    label: 'Frontend',
},{
    id: 2,
    value: 'backend',
    label: 'Backend',
},{
    id: 3,
    value: 'deployment',
    label: 'Deployment',
},{
    id: 4,
    value: 'bookkeeping',
    label: 'Book Keeping',
}]


export const budget = [{
    id: 1,
    value: '$0-$500',
   
},{
    id: 2,
    value: '$500-$1000',
  
},{
    id: 3,
    value: '$1000-$5000',
 
},{
    id: 4,
    value: '$5000-$20000',
 
}]