import React, { useState, useEffect, useRef } from "react";
import { Modal, Carousel } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const PictureModalCarousel = ({ data, show, selectedImage, onHide }) => {
  const [currentImage, setCurrentImage] = useState(selectedImage);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  useEffect(() => {
    setCurrentImage(selectedImage);
  }, [selectedImage]);

  const selectedPortfolio = data.portfolios_ids?.find(
    (portfolio) => portfolio.thumbnail === selectedImage
  );
  const portfolioPictures = selectedPortfolio
    ? JSON.parse(selectedPortfolio.pictures)
    : [];

  const orderedPictures = [
    selectedImage,
    ...portfolioPictures.filter((pic) => pic !== selectedImage),
  ];
  const modalBodyRef = useRef(null);

 
  const handleImageClick = (picture) => {
    setCurrentImage(picture);
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleThumbnailLoad = () => {
    setThumbnailLoaded(true);
  };

  return (
    <>
      {selectedImage && (
        <Modal show={show} centered size="xl" onHide={onHide}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body ref={modalBodyRef}>
            <Carousel
              activeIndex={orderedPictures.indexOf(currentImage)}
              onSelect={(selectedIndex) =>
                setCurrentImage(orderedPictures[selectedIndex])
              }
              interval={null}
              indicators={false}
            >
              {orderedPictures.map((picture) => (
                <Carousel.Item key={picture}>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={800}
                    sx={{
                      borderRadius: "1em",
                      marginBottom: "1rem",
                    }}
                    style={{ display: thumbnailLoaded ? "none" : "block" }}
                  />

                  <img
                    src={picture}
                    alt="projectImages"
                    className={`${
                      thumbnailLoaded
                        ? "img-fluid w-100 mb-4 cursor-pointer border border-2 border-dark"
                        : "d-none"
                    }`}
                    style={{ borderRadius: "1em" }}
                    onClick={() => handleImageClick(picture)}
                    onLoad={handleThumbnailLoad}
                  />
                </Carousel.Item>
              ))}
            </Carousel>

            <div className="row d-flex justify-content-center mt-3">
              {thumbnailLoaded &&
                orderedPictures.map((picture) => (
                  <div
                    className="col-3 mt-2"
                    style={{ width: "10em" }}
                    key={picture}
                  >
                    {picture === orderedPictures[0] ? (
                      <img
                        src={picture}
                        alt="projectThumbnail"
                        className={`img-fluid w-100 mb-4 cursor-pointer ${
                          currentImage === picture
                            ? "border border-3 border-dark"
                            : "opacity-75 border border-1 border-dark"
                        }`}
                        style={{
                          height: "6em",
                          width: "10em",
                          objectFit: "cover",
                          borderRadius: "1em",
                          transform:
                            currentImage === picture ? "scale(1.2)" : "",
                        }}
                        onClick={() => handleImageClick(picture)}
                      />
                    ) : (
                      <img
                        src={picture}
                        alt="projectImages"
                        className={`img-fluid w-100 mb-4 cursor-pointer ${
                          currentImage === picture
                            ? "border border-3 border-dark"
                            : "opacity-75 border border-1 border-dark hover-img"
                        }`}
                        style={{
                          height: "6em",
                          width: "10em",
                          objectFit: "cover",
                          borderRadius: "1em",
                          transform:
                            currentImage === picture ? "scale(1.2)" : "",
                        }}
                        onClick={() => handleImageClick(picture)}
                      />
                    )}
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PictureModalCarousel;
