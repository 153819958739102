export const applyColors = async (value, data) => {

  let newValue = await value.replace(/<<color-fore-1>>/g, `color:${data.color1};`);
  newValue = await newValue.replace(/<<color-fore-2>>/g, `color:${data.color2};`);
  newValue = await newValue.replace(/<<color-fore-3>>/g, `color:${data.color3};`);
  newValue = await newValue.replace(/<<color-fore-4>>/g, `color:${data.color4};`);
  
  newValue = await newValue.replace(/<<color-bg-1>>/g, `background-color:${data.color1}CC;`);
  newValue = await newValue.replace(/<<color-bg-2>>/g, `background-color:${data.color2}CC;`);
  newValue = await newValue.replace(/<<color-bg-3>>/g, `background-color:${data.color3}CC;`);
  newValue = await newValue.replace(/<<color-bg-4>>/g, `background-color:${data.color4}CC;`);
  return newValue;

}

export const setRootColors = (color1, color2, color3, color4,  profile_photo_2) => {
  document.documentElement.style.setProperty('--rootColorFore1', color1);
  document.documentElement.style.setProperty('--rootColorFore2', color2);
  document.documentElement.style.setProperty('--rootColorFore3', color3);
  document.documentElement.style.setProperty('--rootColorFore4', color4);
  document.documentElement.style.setProperty('--rootColorBg1', `${color1}CC`);
  document.documentElement.style.setProperty('--rootColorBg2', `${color2}CC`);
  document.documentElement.style.setProperty('--rootColorBg3', `${color3}CC`);
  document.documentElement.style.setProperty('--rootColorBg4', `${color4}CC`);
  document.documentElement.style.setProperty('--bgimg', `url(${profile_photo_2})`);
};

